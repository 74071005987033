(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/pool-betting/hooks/assets/javascripts/use-examine-player-wager.js') >= 0) return;  svs.modules.push('/components/pool-betting/hooks/assets/javascripts/use-examine-player-wager.js');
"use strict";


const {
  useEffect
} = React;
const {
  useExaminePlayerWagerMutation
} = svs.components.tipsen.store.tipsenApi;
const {
  useBestBetRows
} = svs.components.poolBetting.bestBetRows.hooks;
const useExaminePlayerWager = _ref => {
  let {
    examined,
    isDrawFinalized,
    productId,
    wagerId,
    drawNumber
  } = _ref;
  const [examineWagerMutation] = useExaminePlayerWagerMutation();
  const {
    isInitialized: isMaskedOutcomesInitialized,
    maskedOutcomes
  } = useBestBetRows();
  useEffect(() => {
    if (examined !== false || !isDrawFinalized || !drawNumber) {
      return;
    }
    if (isMaskedOutcomesInitialized && maskedOutcomes.length === 0) {
      examineWagerMutation({
        productId,
        wagerId,
        drawNumber
      });
    }
  }, [drawNumber, examineWagerMutation, examined, isDrawFinalized, isMaskedOutcomesInitialized, maskedOutcomes, productId, wagerId]);
};
setGlobal('svs.components.poolBetting.hooks.useExaminePlayerWager', useExaminePlayerWager);

 })(window);